import { API_URL_BASE, API_VERSION } from "requests/base";

export * from "./account";
export * from "./admin";
// export * from "./agency";
export * from "./billedEntity";
export * from "./cart";
export * from "./client";
export * from "./insured";
export * from "./invoice";
export * from "./payment";
export * from "./payment-instrument";
export * from "./premium-finance";
export * from "./statement";
export * from "./submissionBill";
export * from "./user";

export const dashboardPath = () => {
  return "/dashboard/";
};

export const pdfPath = (url: string) => {
  return `${API_URL_BASE}/${API_VERSION}/system/print/pdf?url=${fullUrl(url)}`;
};

export const fullUrl = (url: string) => {
  const domain = `${window.location.protocol}//${window.location.host}`;
  return `${domain}${url}`;
};
