import { SubmissionBill } from "types";

interface SubmissionBillPathProps {
  print?: boolean;
}

export const submissionBillPath = (
  submissionBill: SubmissionBill,
  opts?: SubmissionBillPathProps
) => {
  if (opts?.print) {
    return `/submission-bill/${submissionBill.id}/print/`;
  }
  return `/submission-bill/${submissionBill.id}/`;
};
