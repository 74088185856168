import { Maybe } from "types";

interface PaymentInstrumentsPathProps {
  client_id?: Maybe<string>;
  insured_id?: Maybe<string>;
}

export const paymentInstrumentsPath = (opts: PaymentInstrumentsPathProps) => {
  if (opts.client_id) {
    return `/clients/${opts.client_id}/payment-instruments`;
  }
  if (opts.insured_id) {
    return `/insured/${opts.insured_id}/payment-instruments`;
  }
};
