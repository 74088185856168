import { CrudPathProps, addQuery } from "routes/crud";
import { Client, Policy } from "types";

export type ClientPolicyPathTab =
  | "payables"
  | "receivables"
  | "premium-finance"
  | "comms"
  | null;

export interface ClientPoliciesPathProps extends CrudPathProps {
  policy?: Policy;
  tab?: ClientPolicyPathTab;
}

export const clientPoliciesPath = (
  client: Client,
  opts?: ClientPoliciesPathProps
) => {
  if (!client) {
    return "";
  }
  let url = "";
  if (opts?.policy) {
    if (opts.update) {
      url = `/clients/${client.id}/policies/${opts.policy.id}/edit`;
    } else {
      if (opts.tab) {
        url = `/clients/${client.id}/policies/${opts.policy.id}/${opts.tab}/`;
      } else {
        url = `/clients/${client.id}/policies/${opts.policy.id}/`;
      }
    }
  } else {
    if (opts?.create) {
      url = `/clients/${client.id}/policies/new`;
    } else {
      url = `/clients/${client.id}/policies/`;
    }
  }
  if (opts?.query) {
    url = addQuery(url, opts.query);
  }
  return url;
};
