import { CrudPathProps } from "routes/crud";
import {
  AgencyPartner,
  AgencyPartnerContact,
  Cart,
  Client,
  Maybe,
  Statement,
} from "types";

export type ClientAgencyPathTab =
  | "payables"
  | "comms"
  | "payments"
  | "profile"
  | "policies"
  | null;

export interface ClientAgencyPathProps extends CrudPathProps {
  agency?: Maybe<AgencyPartner>;
  agency_id?: Maybe<string>;
  tab?: ClientAgencyPathTab;
}

export const clientAgencyPath = (
  client: Client,
  opts?: ClientAgencyPathProps
) => {
  const agency_id = opts?.agency?.id || opts?.agency_id;
  if (agency_id) {
    if (opts?.update) {
      return `/clients/${client.id}/agency/${agency_id}/edit/`;
    } else if (opts?.tab) {
      return `/clients/${client.id}/agency/${agency_id}/${opts.tab}/`;
    } else {
      return `/clients/${client.id}/agency/${agency_id}/`;
    }
  } else {
    if (opts?.create) {
      return `/clients/${client.id}/agency/new/`;
    } else {
      return `/clients/${client.id}/agency/`;
    }
  }
};

export interface ClientAgencyContactPathProps extends CrudPathProps {
  agency: AgencyPartner;
  contact?: Maybe<AgencyPartnerContact>;
}

export const clientAgencyContactPath = (
  client: Client,
  opts: ClientAgencyContactPathProps
) => {
  if (opts?.contact?.id) {
    return `/clients/${client.id}/agency/${opts.agency.id}/contacts/${opts?.contact?.id}/edit`;
  } else {
    return `/clients/${client.id}/agency/${opts.agency.id}/contacts/new`;
  }
};

export interface ClientAgencyCartCrudPathProps extends CrudPathProps {
  cart?: Maybe<Cart>;
  review?: boolean;
}

export const clientAgencyCartPath = (
  client: Client,
  agency: AgencyPartner,
  opts?: ClientAgencyCartCrudPathProps
) => {
  if (opts?.cart) {
    if (opts?.update) {
      return `/clients/${client.id}/agency/${agency.id}/carts/${opts.cart.id}/edit/`;
    } else if (opts?.review) {
      return `/clients/${client.id}/agency/${agency.id}/carts/${opts.cart.id}/review/`;
    } else {
      return `/clients/${client.id}/agency/${agency.id}/carts/${opts.cart.id}/`;
    }
  } else {
    if (opts?.create) {
      return `/clients/${client.id}/agency/${agency.id}/carts/new/`;
    } else {
      return `/clients/${client.id}/agency/${agency.id}/carts/`;
    }
  }
};

export interface ClientAgencyStatemntCrudPathProps extends CrudPathProps {
  statement?: Maybe<Statement>;
  preview?: boolean;
}

export const clientAgencyStatementPath = (
  client: Client,
  agency: AgencyPartner,
  opts?: ClientAgencyStatemntCrudPathProps
) => {
  if (opts?.statement) {
    if (opts?.update) {
      return `/clients/${client.id}/agency/${agency.id}/statements/${opts.statement.id}/edit/`;
    } else if (opts?.preview) {
      return `/clients/${client.id}/agency/${agency.id}/statements/${opts.statement.id}/preview/`;
    } else {
      return `/clients/${client.id}/agency/${agency.id}/statements/${opts.statement.id}/`;
    }
  } else {
    if (opts?.create) {
      return `/clients/${client.id}/agency/${agency.id}/statements/new/`;
    } else {
      return `/clients/${client.id}/agency/${agency.id}/statements/`;
    }
  }
};
