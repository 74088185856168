import { ConsolidatedInvoice, EndInsured, Invoice, Maybe } from "types";

interface InsuredBillsPathProps {
  invoice?: Invoice;
}

export const insuredBillPath = (
  insured: Maybe<EndInsured>,
  opts?: InsuredBillsPathProps
) => {
  if (opts?.invoice) {
    return `/invoice/${opts.invoice.id}/`;
  } else if (opts?.invoice?.insured_id) {
    return `/insured/${opts.invoice.insured_id}/bills/`;
  } else {
    return `/insured/${insured?.id}/bills/`;
  }
};

interface InsuredConsolidatedBillsPathProps {
  invoice?: ConsolidatedInvoice;
}
export const insuredConsolidatedBillPath = (
  insured: Maybe<EndInsured>,
  opts?: InsuredConsolidatedBillsPathProps
) => {
  if (opts?.invoice) {
    return `/consolidated-invoice/${opts.invoice.id}/`;
  } else if (opts?.invoice?.billed_to_id) {
    return `/insured/${opts.invoice.billed_to_id}/consolidated-bills/`;
  } else {
    return `/insured/${insured?.id}/consolidated-bills/`;
  }
};
