import { CrudPathProps } from "routes/crud";
import { Maybe, Carrier } from "types";

export interface AdminCarrierCrudPathProps extends CrudPathProps {
  carrier?: Maybe<Carrier>;
}

export const adminCarriersPath = (opts?: AdminCarrierCrudPathProps) => {
  if (opts?.carrier) {
    if (opts?.update) {
      return `/admin/carriers/${opts.carrier.id}/edit/`;
    } else {
      return `/admin/carriers/${opts.carrier.id}/`;
    }
  } else {
    if (opts?.create) {
      return "/admin/carriers/new/";
    } else {
      return "/admin/carriers/";
    }
  }
};
