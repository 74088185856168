import { CrudPathProps } from "routes/crud";
import { EndInsured, Client, Maybe } from "types";

export interface AdminClientInsuredPathProps extends CrudPathProps {
  client?: Maybe<Client>;
  endInsured?: Maybe<EndInsured>;
}

export const adminClientInsuredPath = (opts?: AdminClientInsuredPathProps) => {
  if (opts?.client?.id && opts?.endInsured?.id) {
    if (opts?.update) {
      return `/admin/clients/${opts.client.id}/insureds/${opts.endInsured.id}/edit/`;
    }
    // TODO: eventually admins can create end-insureds on behalf of a client. future ticket
  }
  return "";
};
