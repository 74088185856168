import { CrudPathProps } from "routes/crud";
import { PremiumFinancePartner, Client, Maybe } from "types";

export interface ClientPremiumFinancePartnerPathProps extends CrudPathProps {
  premium_finance_partner?: Maybe<PremiumFinancePartner>;
  pf_client_id?: Maybe<string>;
}

export const clientPremiumFinancePartnerPath = (
  client: Client,
  opts?: ClientPremiumFinancePartnerPathProps
) => {
  const pf_client_id = opts?.premium_finance_partner?.id || opts?.pf_client_id;
  if (pf_client_id) {
    return `/clients/${client.id}/premium-finance-partners/${pf_client_id}/`;
  } else {
    return `/clients/${client.id}/premium-finance-partners/`;
  }
};
