import { CrudPathProps } from "routes/crud";
import { Client, Maybe, Carrier } from "types";

export type ClientCarrierPathTab = "payments" | "policies" | null;

export interface ClientCarrierPathProps extends CrudPathProps {
  carrier?: Maybe<Carrier>;
  carrier_id?: Maybe<string>;
  tab?: ClientCarrierPathTab;
}

export const clientCarriersPath = (
  client: Client,
  opts?: ClientCarrierPathProps
) => {
  const pf_client_id = opts?.carrier?.id || opts?.carrier_id;
  if (pf_client_id) {
    if (opts?.tab) {
      return `/clients/${client.id}/carriers/${pf_client_id}/${opts?.tab}`;
    }
    return `/clients/${client.id}/carriers/${pf_client_id}/`;
  } else {
    return `/clients/${client.id}/carriers/`;
  }
};
