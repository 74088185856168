import { CrudPathProps } from "routes/crud";
import { AgencyPartner, Client, Maybe } from "types";

export interface AdminClientAgencyPathProps extends CrudPathProps {
  client?: Maybe<Client>;
  agency?: Maybe<AgencyPartner>;
}

export const adminClientAgencyPath = (opts?: AdminClientAgencyPathProps) => {
  if (opts?.client?.id && opts?.agency?.id) {
    if (opts?.update) {
      return `/admin/clients/${opts?.client?.id}/agencies/${opts.agency.id}/edit/`;
    }
    // TODO: eventually admins can create agency partners on behalf of a client. future ticket
  }
};
