export interface LoginPathParams {
  client_id?: string;
  redirect_url?: string;
}

export const loginPath = (params: LoginPathParams) => {
  let url = "/login/";
  if (params.client_id) {
    url = `${url}${params.client_id}`;
  }
  if (params.redirect_url) {
    url = `${url}?redirect_url=${params.redirect_url}`;
  }
  return url;
};

export const accountSettingsPath = () => {
  return "/account-settings/";
};

export const requestPasswordReset = () => {
  return "/user/password/reset/";
};

export const verifyPasswordReset = (id: string) => {
  return `/user/password/reset/token/${id}/`;
};
