import { CrudPathProps } from "routes/crud";
import { Maybe, User } from "types";

export interface AdminUserCrudPathProps extends CrudPathProps {
  user?: Maybe<User>;
}

export const adminUserPath = (opts?: AdminUserCrudPathProps) => {
  if (opts?.user) {
    if (opts?.update) {
      return `/admin/users/${opts.user.id}/edit/`;
    } else {
      return `/admin/users/${opts.user.id}/`;
    }
  } else {
    if (opts?.create) {
      return "/admin/users/new/";
    } else {
      return "/admin/users/";
    }
  }
};
