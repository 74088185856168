import { PremiumFinanceQuote } from "types";

interface PremiumFinanceQuotePathProps {
  print?: boolean;
}

export const premiumFinanceQuotePath = (
  pfQuote: PremiumFinanceQuote,
  opts?: PremiumFinanceQuotePathProps
) => {
  if (opts?.print) {
    return `/premium-finance-quote/${pfQuote.id}/print/`;
  }
  return `/premium-finance-quote/${pfQuote.id}/`;
};
