import { CrudPathProps } from "routes/crud";
import { Client, Maybe, Payment } from "types";

export interface ClientPaymentCrudPathProps extends CrudPathProps {
  payment?: Maybe<Payment>;
  payment_id?: string;
}

export const clientPaymentsPath = (
  client: Client,
  opts?: ClientPaymentCrudPathProps
) => {
  if (!client) {
    return "";
  }
  if (opts?.payment?.id || opts?.payment_id) {
    if (opts?.update) {
      return `/clients/${client.id}/payments/${
        opts.payment?.id || opts.payment_id
      }/edit/`;
    } else {
      return `/clients/${client.id}/payments/${
        opts.payment?.id || opts.payment_id
      }/`;
    }
  } else {
    if (opts?.create) {
      return `/clients/${client.id}/payments/new/`;
    } else {
      return `/clients/${client.id}/payments/`;
    }
  }
};
