import { Statement } from "types";

interface StatementPathProps {
  print?: boolean;
}

export const statementPath = (
  statement: Statement,
  opts?: StatementPathProps
) => {
  if (opts?.print) {
    return `/statement/${statement.id}/print/`;
  }
  return `/statement/${statement.id}/`;
};
