import { API_URL_BASE } from "requests/base";
import { Query, addQuery } from "routes/crud";
import { Client, ReportType } from "types";

export interface ClientReportPathProps {
  reportType?: ReportType;
  download?: boolean;
  query?: Query;
}

export const clientReportsPath = (
  client: Client,
  opts?: ClientReportPathProps
) => {
  if (opts?.reportType) {
    if (opts?.download) {
      const url = `${API_URL_BASE}/report/csv`;
      if (opts?.query) {
        return addQuery(url, opts.query);
      } else {
        return url;
      }
    } else {
      return `/clients/${client.id}/reports/${opts.reportType}/`;
    }
  } else {
    return `/clients/${client.id}/reports/`;
  }
};
