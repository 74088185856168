import { CrudPathProps, addQuery } from "routes/crud";
import { Client } from "types";
import { Submission } from "types/submission";

export interface ClientSubmissionsPathProps extends CrudPathProps {
  submission?: Submission;
}

export const clientSubmissionsPath = (
  client: Client,
  opts?: ClientSubmissionsPathProps
) => {
  if (!client) {
    return "";
  }
  let url = "";
  if (opts?.submission) {
    if (opts.update) {
      url = `/clients/${client.id}/submissions/${opts.submission.id}/edit`;
    } else {
      url = `/clients/${client.id}/submissions/${opts.submission.id}/`;
    }
  } else {
    if (opts?.create) {
      url = `/clients/${client.id}/submissions/new`;
    } else {
      url = `/clients/${client.id}/submissions/`;
    }
  }
  if (opts?.query) {
    url = addQuery(url, opts.query);
  }
  return url;
};
