import { Maybe, User } from "types";
import { CrudPathProps } from "./crud";

interface UserCrudPathProps extends CrudPathProps {
  user?: Maybe<User>;
}

export const userPath = (opts?: UserCrudPathProps) => {
  if (opts?.user) {
    if (opts?.update) {
      return `/users/${opts.user.id}/edit/`;
    } else {
      return `/users/${opts.user.id}/`;
    }
  } else {
    if (opts?.create) {
      return "/users/new/";
    } else {
      return "/users/";
    }
  }
};
